


































































import Vue from 'vue'
import Component from 'vue-class-component'
import AccountingFilters from "@/modules/accounting/components/AccountingFilters.vue";
import AccountingTable from "@/modules/accounting/components/AccountingTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import GenproxModal from "@/components/layout/GenproxModal.vue";
import SygniToggleSwitch from '@/components/inputs/SygniToggleSwitch.vue';
import FileUploader from "@/components/FileUploader.vue";
import {TableData} from "@/modules/genprox/modules/profile/store/types";
import {mapState} from "vuex";
import { DocumentItem } from '@/modules/accounting/store/types';
import { BTable } from 'bootstrap-vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import FlagsManagementModal from '../components/FlagsManagementModal.vue';
import FlagAccessModal from '../components/FlagAccessModal.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';

@Component({
  components: { AccountingFilters, FileUploader, AccountingTable, SygniToggleSwitch, SygniModal, GenproxModal, BTable, SygniRoundedButton, FlagsManagementModal, FlagAccessModal, SygniContainerTitle, GenproxPageHeading },
  computed: mapState('accounting', {
    documentsTable: (state: any) => state.documentsTable,
    isBusy: (state: any) => state.documentsTableIsBusy,
    perPage: (state: any) => state.documentsTable.perPage,
    documentsUploadProgress: (state: any) => state.documentsUploadProgress,
  })
})
export default class accountingModule extends Vue {
  readonly supportedFileFormats: Array<string> = ['pdf', 'jpeg', 'jpg', 'png'];
  tempFile: string = "";
  documentsTable!: TableData<DocumentItem>;
  isBusy!: boolean;
  showDeleteModal: boolean = false;
  showUploadFilesModal: boolean = false;
  uploadFilesModalItems: Array<any> = [];
  deleteFilesModalItems: Array<any> = [];
  deleteItems: Array<string> | string = '';
  filtersQueryString: string = '';
  isLoading: boolean = false;
  showFlagManagementModal: boolean = false;
  showFlagAccessModal: boolean = false;
  sharingFlag: any = null;
  detailedOcr: boolean = false;

  enableFlagAccessModal(flag: any) {
    this.sharingFlag = flag;
    this.showFlagAccessModal = true;
  }

  closeFlagAccessModal() {
    this.showFlagAccessModal = false;
    this.sharingFlag = null;
  }

  enableFlagManagementModal() {
    this.showFlagManagementModal = true;
  }

  closeFlagManagementModal() {
    this.showFlagManagementModal = false;
  }

  async addFiles(files: Array<File>): Promise<void> {
    (this.$refs.accountingTable as AccountingTable).closeDocument();
    const payload = { files, type: this.detailedOcr ? 'product-items' : 'vat-positions' };
    this.$store.commit('accounting/setDocumentsTableBusy', true);
    const requests: Array<any> = await this.$store.dispatch('accounting/uploadDocuments', payload);
    const response = [];

    for (const [key, value] of Object.entries(requests)) {
      response.push({ fileName: key, status: value.status, errors: value.status == 'rejected' ? value.data?.response?.data?.violations : [] });
    }

    this.displayUploadFilesModal(response);
    await (this.$refs.accountingTable as AccountingTable).onFiltersChange(this.filtersQueryString);
    this.$store.commit('accounting/setDocumentsUploadProgress', -1);
  }

  get allFilesUploaded() {
    return !!(this.successfulUploadedFilesLength == this.uploadFilesModalItems?.length)
  }

  displayUploadFilesModal(items: Array<any>) {
    this.uploadFilesModalItems = items;
    (this.$refs.uploadFilesTable as BTable).refresh();
    this.showUploadFilesModal = true;

    if (this.allFilesUploaded) {
      setTimeout(() => {
        this.closeUploadFilesModal();
      }, 2500)
    }
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    (this.$refs.accountingTable as AccountingTable).onFiltersChange(queryString);
  }

  confirmDelete(item: any) {
    this.deleteItems = item.id;
    this.deleteFilesModalItems = [item];
    this.showDeleteModal = true;
  }

  confirmBulkDelete(ids: Array<any>) {
    const items = this.documentsTable.items.filter(el => ids.includes(el.id));
    this.deleteFilesModalItems = items;
    this.deleteItems = ids;

    this.showDeleteModal = true;
  }

  async bookDocuments(ids: any[]) {
    this.isLoading = true;
    try {
      await this.$store.dispatch('accounting/bulkBookDocuments', ids);
      (this.$refs.accountingTable as AccountingTable).refreshTable();
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Documents were booked.'
      });
    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false;
  }

  closeDeleteModal() {
    this.showDeleteModal = false;
  }

  closeUploadFilesModal() {
    this.showUploadFilesModal = false;
    this.uploadFilesModalItems = [];
  }

  getFileStatusClass(status: string) {
    let className = 'primary';

    if(status == 'rejected') {
      className = 'danger';
    }

    return className;
  }

  getFileStatusText(status: string) {
    let statusText = 'OK';

    if(status == 'rejected') {
      statusText = 'FAILED';
    }

    return statusText;
  }

  exportCsvByFilters() {
    (this.$refs.accountingTable as AccountingTable).exportCsvByFilters(this.filtersQueryString);
  }

  async confirmDeleteAction() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('accounting/deleteDocuments', this.deleteItems);
      this.isLoading = false;
      this.showDeleteModal = false;
      this.deleteItems = '';
      await (this.$refs.accountingTable as AccountingTable).onFiltersChange(this.filtersQueryString);
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Document was deleted.'
      });
    } catch(error) {
      this.isLoading = false;
      this.showDeleteModal = false;
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: 'Something went wrong, please try again later.'
      });
    }
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isApproverOnly() {
    return !(this.activeUserData?.role?.workflow?.includes('user') || this.activeUserData?.role?.workflow?.includes('admin') || this.activeUserData?.role?.workflow?.includes('accountant'))
  }

  get showFileUploader() {
    if(!this.activeUserData) return false;

    return !this.isApproverOnly;
  }

  get successfulUploadedFilesLength(): number {
    const items = this.uploadFilesModalItems.filter(el => el.status == 'fulfilled');

    return items ? items.length : 0;
  }

  get processedFilesDescription() {
    return `${this.successfulUploadedFilesLength}/${this.uploadFilesModalItems.length} files successfully processed`;
  }

  async beforeMount() {
    await this.$store.dispatch('genprox/getFlags');
    if(this.activeUserData.context.context == 'company' || this.activeUserData.context.context == 'fund' || this.activeUserData.context.context == 'fund-manager') {
      await this.$store.dispatch('accounting/getDictionaries');
    }
    this.detailedOcr = !(this.activeUserData?.ocrItemsType === 'vat-positions')
  }
}
