






































































































































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniFlagSelect from "@/components/inputs/SygniFlagSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
// import {SelectOption} from "@/store/types";
import { Filter, FilterFunctions, FilterHelpers, FilterMixin, FilterOperators } from "@/shared/mixins/FilterMixin";
// import {AddProductSelects, InvestorSelects} from "@/modules/shared/dictionary-store/types";
import { AddProductSelects } from "@/modules/shared/dictionary-store/types";
import { mapGetters } from "vuex";
import { Statues } from "@/shared/interfaces/Statues";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import { Dictionaries } from '@/modules/accounting/store/types';
import { SelectOption } from '@/store/types';
import { Watch } from 'vue-property-decorator';
import Utils from '@/modules/shared/utils/utils';
import { FlagOption } from '@/modules/genprox/store/types'
import AccountingPeriodsTable from '@/modules/accounting/components/AccountingPeriodsTable.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: {
    SygniModal,
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniDatePicker,
    SygniContainerTitle,
    SygniRoundedButton,
    SygniSquareButton,
    SygniSelect,
    SygniInput,
    SygniMultiSelect,
    SygniFlagSelect,
    AccountingPeriodsTable,
    GenproxModal,
    GenproxButton
  },
  computed: {
    ...mapGetters('accounting', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class AccountingFilters extends mixins(FilterMixin) {
  showAccountingPeriodsModal: boolean = false;
  productSelects!: AddProductSelects;
  dictionaries!: Dictionaries;
  readonly Statues = Statues;
  areDocumentNumberOptionsLoading: boolean = false;
  documentNumberOptions: any = [];
  counterPartyNameOptions: any = [];
  areCounterPartyNameOptionsLoading: boolean = false;
  accountingPeriod: string = '';
  itemsUrl: string = 'document';

  toggleFilters: boolean = false;

  filters: Record<string, Filter> = {
    documentType: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    documentNumber: {
      value: null,
      getQueryValue: FilterFunctions.string,
      operator: FilterOperators.like,
      type: 'string'
    },
    correctedDocumentNumber: {
      value: null,
      getQueryValue: FilterFunctions.string,
      operator: FilterOperators.like,
      type: 'string'
    },
    fileName: {
      value: null,
      getQueryValue: FilterFunctions.string,
      operator: FilterOperators.like,
      type: 'string'
    },
    paymentStatus: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    glAccount: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    costCenter: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    taxNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    accountingId: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    grossAmount: {
      value: { from: null, to: null },
      getQueryValue: FilterFunctions.range,
      operator: FilterOperators.like,
      type: 'range'
    },
    issueDate: {
      value: null,
      getQueryValue: FilterFunctions.dateRange,
      operator: FilterOperators.like,
      type: 'dateRange'
    },
    saleDate: {
      value: [null, null],
      getQueryValue: FilterFunctions.dateRange,
      operator: FilterOperators.like,
      type: 'dateRange'
    },
    paymentDate: {
      value: null,
      getQueryValue: FilterFunctions.dateRange,
      operator: FilterOperators.like,
      type: 'dateRange'
    },
    additionDate: {
      value: null,
      getQueryValue: FilterFunctions.dateRange,
      operator: FilterOperators.like,
      type: 'dateRange'
    },
    documentReceiptDate: {
      value: [null, null],
      getQueryValue: FilterFunctions.dateRange,
      operator: FilterOperators.like,
      type: 'dateRange'
    },
    counterPartyName: {
      value: null,
      getQueryValue: FilterFunctions.string,
      operator: FilterOperators.like,
      type: 'string'
    },
    status: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    currency: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
    flags: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.contain, type: 'string' },
  };

  documentStatusOptions: Array<SelectOption<string>> = [
    {
      label: 'Uploaded',
      value: 'uploaded'
    },
    {
      label: 'Processed',
      value: 'processed'
    },
    {
      label: 'Sent for approval',
      value: 'sent-to-approval'
    },
    {
      label: 'Approved',
      value: 'approved'
    },
    {
      label: 'Booked',
      value: 'booked'
    },
    {
      label: 'Booking',
      value: 'booking'
    },
    {
      label: 'Failed',
      value: 'failed'
    },
    {
      label: 'Rejected',
      value: 'rejected'
    },
    {
      label: 'Rejected by operator',
      value: 'rejected-by-operator'
    },
    {
      label: 'Cancelled',
      value: 'canceled'
    }
  ];

  selectedDocumentStatusOptions: Array<any> = [];
  selectedCostCenterOptions: Array<any> = [];
  selectedGlAccountOptions: Array<any> = [];
  selectedDocumentCurrencies: Array<any> = [];
  selectedPaymentStatuses: Array<any> = [];
  selectedDocumentTypeOptions: Array<any> = [];
  selectedFlags: Array<any> = [];

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get isAccountingPeriodManager() {
    return this.activeUserData?.role?.accountingPeriodManager;
  }

  get flags() {
    return this.$store.getters['genprox/flags'];
  }

  get flagOptions(): SelectOption<string> {
    return this.flags.map((el: FlagOption) => {
      return {
        label: el.name,
        value: el.id,
        color: el.color
      }
    })
  }

  get toggleText(): string {
    return this.toggleFilters ? 'SHOW' : 'HIDE';
  }

  get tableQueryFilters() {
    return this.$store.getters['accounting/getDocumentsTableQuery'].filters
  }

  openAccountingPeriodsModal() {
    this.showAccountingPeriodsModal = true;
  }

  closeAccountingPeriodsModal() {
    this.showAccountingPeriodsModal = false;
  }

  updateStatuses(statuses: Array<string>) {
    this.filters.status.value = statuses;
  }

  updatePaymentStatuses(statuses: Array<string>) {
    this.filters.paymentStatus.value = statuses;
  }

  updateCostCenters(statuses: Array<string>) {
    this.filters.costCenter.value = statuses;
  }

  updateGlAccounts(statuses: Array<string>) {
    this.filters.glAccount.value = statuses;
  }

  updateDocumentTypes(statuses: Array<string>) {
    this.filters.documentType.value = statuses;
  }

  updateCurrencies(currencies: Array<string>) {
    this.filters.currency.value = currencies;
  }

  updateFlags(flags: Array<string>) {
    this.filters.flags.value = flags;
  }

  toggleAllStatuses() {
    if (this.selectedDocumentStatusOptions.length < this.documentStatusOptions.length) {
      const statuses = this.documentStatusOptions.map(el => el.value);
      this.selectedDocumentStatusOptions = statuses;
      this.updateStatuses(statuses);
    } else {
      this.selectedDocumentStatusOptions = [];
      this.updateStatuses([]);
    }
  }

  toggleAllPaymentStatuses() {
    if (this.selectedPaymentStatuses.length < this.dictionaries?.paymentStatuses.length) {
      const statuses = this.dictionaries.paymentStatuses.map(el => el.value);
      this.selectedPaymentStatuses = statuses;
      this.updatePaymentStatuses(statuses);
    } else {
      this.selectedPaymentStatuses = [];
      this.updatePaymentStatuses([]);
    }
  }

  toggleAllCostCenters() {
    if (this.selectedCostCenterOptions.length < this.dictionaries.costCenters.length) {
      const statuses = this.dictionaries.costCenters.map(el => el.value);
      this.selectedCostCenterOptions = statuses;
      this.updateCostCenters(statuses);
    } else {
      this.selectedCostCenterOptions = [];
      this.updateCostCenters([]);
    }
  }

  toggleAllGlAccounts() {
    if (this.selectedGlAccountOptions.length < this.dictionaries.glAccounts.length) {
      const statuses = this.dictionaries.glAccounts.map(el => el.value);
      this.selectedGlAccountOptions = statuses;
      this.updateGlAccounts(statuses);
    } else {
      this.selectedGlAccountOptions = [];
      this.updateGlAccounts([]);
    }
  }

  toggleAllDocumentTypes() {
    if (this.selectedDocumentTypeOptions.length < this.dictionaries.type.length) {
      const statuses = this.dictionaries.type.map(el => el.value);
      this.selectedDocumentTypeOptions = statuses;
      this.updateDocumentTypes(statuses);
    } else {
      this.selectedDocumentTypeOptions = [];
      this.updateDocumentTypes([]);
    }
  }

  toggleAllCurrencies() {
    if (this.selectedDocumentCurrencies.length < this.dictionaries.currency.length) {
      const currencies = this.dictionaries.currency.map(el => el.value);
      this.selectedDocumentCurrencies = currencies;
      this.updateCurrencies(currencies);
    } else {
      this.selectedDocumentCurrencies = [];
      this.updateCurrencies([]);
    }
  }

  toggleAllFlags() {
    if (this.selectedFlags.length < this.flags.length) {
      const flags = this.flags.map((el: any) => el.id);
      this.selectedFlags = flags;
      this.updateFlags(flags);
    } else {
      this.selectedFlags = [];
      this.updateFlags([]);
    }
  }

  getDocumentStatusClassName(status: string): string {
    let className = 'default';
    switch (status?.toLowerCase()) {
      case ('processed'):
        className = 'default';
        break;
      case ('approved'):
        className = 'success';
        break;
      case ('sent-to-approval'):
      case ('booking'):
        className = 'warning';
        break;
      case ('uploaded'):
      case ('processing'):
      case ('booked'):
        className = 'primary';
        break;
      case ('failed'):
      case ('rejected'):
      case ('rejected-by-operator'):
      case ('cancelled'):
      case ('canceled'):
        className = 'danger';
        break;
      default:
        className = 'default';
    }

    return className;
  }

  setAccountingPeriod(date: any): void {
    let dateTime = new Date(date);
    dateTime = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
    const dateRange = [date, `${dateTime.getFullYear()}-${Utils.pad(dateTime.getMonth() + 1, 2)}-${Utils.pad(dateTime.getDate(), 2)}`];
    this.filters.documentReceiptDate.value = dateRange;
  }

  handleFiltersToggle(): void {
    this.toggleFilters = !this.toggleFilters;
  }

  createSingleFilterQuery(filter: Filter, filterName: string): string {
    let query: string = this.createCustomQuery(filter, filterName);
    if (query !== '') return query;
    let queryValue = filter.getQueryValue(filter.value);
    if (queryValue === '') return '';
    switch (filter.type) {
      case('dateRange'):
        if (filter.value[0] === null || filter.value[1] === null) break;
        queryValue = filter.getQueryValue(filter.value, 0);
        query += `&filters[${filterName}][0][value]=${queryValue}`;
        query += `&filters[${filterName}][0][operator]=gte`;
        queryValue = filter.getQueryValue(filter.value, 1);
        query += `&filters[${filterName}][1][value]=${queryValue}`;
        query += `&filters[${filterName}][1][operator]=lte`;
        break;
      case('range'):
        if (filter.value.from === null && filter.value.to === null) break;
        queryValue = filter.getQueryValue(filter.value, 0);
        if (queryValue !== null) {
          query += `&filters[${filterName}][0][value]=${queryValue}`;
          query += `&filters[${filterName}][0][operator]=gte`;
        }
        queryValue = filter.getQueryValue(filter.value, 1);
        if (queryValue !== null) {
          query += `&filters[${filterName}][1][value]=${queryValue}`;
          query += `&filters[${filterName}][1][operator]=lte`;
        }
        break;
      case('string'): {
        query += `&filters[${filterName}][0][value]=${queryValue}`;
        query += `&filters[${filterName}][0][operator]=${filter.operator}`;
        break;
      }
    }
    return query;
  }

  applyFilters(): void {
    if (this.isLoading) return;
    this.$emit('filtersChange', this.getFilterQuery());
  }

  clearAll() {
    for (let key in this.filters) {
      if (Array.isArray(this.filters[key].value)) {
        this.filters[key].value = [];
      } else if (this.filters[key].value?.to) {
        this.filters[key].value.to = '';
        this.filters[key].value.from = '';
      } else {
        this.filters[key].value = '';
      }
    }
    this.selectedDocumentStatusOptions = [];
    this.selectedDocumentCurrencies = [];
    this.selectedPaymentStatuses = [];
    this.selectedGlAccountOptions = [];
    this.selectedCostCenterOptions = [];
    this.selectedDocumentTypeOptions = [];
    this.selectedFlags = [];
    this.applyFilters();
  }

  loadDocumentNumberOptions(search: string) {
    this.areDocumentNumberOptionsLoading = true;
    this.$store.dispatch('accounting/getDocumentNumbers', search).then((docs) => {
      this.documentNumberOptions = [];
      docs.forEach((doc: any) => {
        this.documentNumberOptions.push({ label: doc, value: doc });
      });

      this.areDocumentNumberOptionsLoading = false;
    });
  }

  async loadCounterPartyNumberOptions(search: string) {
    this.areCounterPartyNameOptionsLoading = true;
    const counterPartyNames = await this.$store.dispatch('accounting/getCounterPartyNames', search);

    this.counterPartyNameOptions = [];
    counterPartyNames.forEach((doc: any) => {
      this.counterPartyNameOptions.push({ label: doc, value: doc });
    })

    this.$nextTick(() => {
      this.areCounterPartyNameOptionsLoading = false;
    });
  }

  loadFiltersFromStore() {
    const filtersData = FilterHelpers.parseURLFilters(this.tableQueryFilters);

    if (filtersData) {
      let formattedFilters: any = {};

      for (let [key, value] of Object.entries(filtersData)) {
        key = key.substring(1);
        const index = key.indexOf(']');
        key = key.slice(0, index) + key.slice(index + 1);

        const keyName = key.slice(0, key.indexOf('['));

        const indexValue = key.substring(
            key.indexOf('[') + 1,
            key.lastIndexOf(']')
        );

        if (formattedFilters[keyName]) {
          if (indexValue == '0') {
            formattedFilters[keyName] = [formattedFilters[keyName]]
          } else {
            formattedFilters[keyName].push((value as any)[0]);
          }
        } else {
          formattedFilters[keyName] = value;
        }

      }

      for (let [key, value] of Object.entries(formattedFilters)) {
        if (this.filters[key].type == 'dateRange') {
          this.filters[key].value = [
            (value as any)[0].replace(' 00:00:00', ''),
            (value as any)[1].replace(' 23:59:59', ''),
          ];
        } else if (this.filters[key].type == 'range') {
          this.filters[key].value = {
            from: (value as any)[0],
            to: ((value as any)[1])
          }
        } else if (this.filters[key].type == 'string' && this.filters[key].operator != 'in') {
          this.filters[key].value = (value as any)[0]
        } else {
          if (key == 'status') {
            value = (value as any)[0].split(',');
            this.selectedDocumentStatusOptions = (value as any);
          } else if (key == 'currency') {
            value = (value as any)[0].split(',');
            this.selectedDocumentCurrencies = (value as any);
          }
          this.filters[key].value = (value as any);
        }
      }
    }
  }

  mounted() {
    this.loadFiltersFromStore();
  }

  @Watch('filters.documentType.value') onDocumentTypeChange() {
    this.filters.correctedDocumentNumber.value = '';
  }

  @Watch('filters.documentReceiptDate.value') onReceiptDateChange() {
    if (this.filters.documentReceiptDate.value && this.filters.documentReceiptDate.value[0] && this.filters.documentReceiptDate.value[1]) {
      const date = this.filters.documentReceiptDate.value[0];
      let dateTime = new Date(date);

      const firstDay = new Date(dateTime.getFullYear(), dateTime.getMonth(), 1);
      const lastDay = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
      const formattedFirstDay = `${firstDay.getFullYear()}-${Utils.pad(firstDay.getMonth() + 1, 2)}-${Utils.pad(firstDay.getDate(), 2)}`;
      const formattedLastDay = `${lastDay.getFullYear()}-${Utils.pad(lastDay.getMonth() + 1, 2)}-${Utils.pad(lastDay.getDate(), 2)}`;

      if (formattedFirstDay == this.filters.documentReceiptDate.value[0] && formattedLastDay == this.filters.documentReceiptDate.value[1]) {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const month = monthNames[dateTime.getMonth()];
        this.accountingPeriod = `${month}, ${dateTime.getFullYear()}`;
      } else {
        this.accountingPeriod = '';
      }

    } else {
      this.accountingPeriod = '';
    }
  }
}
